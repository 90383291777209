body {
  background: -webkit-linear-gradient(rgb(53, 29, 3, 0.8), rgb(53, 29, 3, 0.8)),
    url("../img/woodgrain.jpg");
  color: #381704;
  padding: 0px;
  margin: 0px;
  width: 100%;
  font-family: arial;
}

a {
  color: #381704;
}

a:hover {
  text-decoration: none;
  color: gold;
}

nav {
  position: fixed;
  padding-left: 5px;
  padding: 5px 20px;
  background-color: white;
  width: 100%;
}

nav h1 {
  font-weight: bold;
  margin: 0;
  padding: 0;
}

footer {
  position: relative;
  bottom: 0;
}

form {
  background-color: #fbfafa;
  font-weight: bolder;
  margin: 10px auto;
  text-align: center;
  padding: 10%;
  text-align: left;
}

form h5 {
  font-weight: bolder;
  text-align: center;
}

form input {
  width: 90%;
  border-radius: 6px;
  padding: 2px;
}
h1.home-page-title {
  font-size: 1em;
}
h2.home-page-title {
  padding: 1em 0;
}
textarea {
  width: 90%;
  height: 90px;
  border-radius: 8px;
}

input[type="submit"] {
  background-color: #31320d;
  color: white;
}

#about {
  background-color: #33320e;
  color: white;
  padding: 10px;
  text-align: center;
}

#about li {
  display: inline-block;
  font-size: 125%;
  list-style: none;
}

#about p {
  font-size: 150%;
}

#background {
  background-image: url("../img/LutherConst2.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  width: 100%;
  margin-top: -5px;
}

#backgroundText {
  margin: 0 20px;
  position: relative;
}

#backgroundText p {
  color: white;
  padding-top: 40%;
  padding-bottom: 5%;
  text-align: center;
  text-shadow: 3px 2px 12px black;
}

#contact {
  background-color: #381704;
  color: white;
  position: relative;
  padding: 20px;
  text-align: center;
  background: -webkit-linear-gradient(rgb(53, 29, 3, 0.8), rgb(53, 29, 3, 0.8)),
    url("../img/woodgrain.jpg");
}

#contact h2 {
  color: white;
}

#contact .row {
  align-items: center;
}

#contact1st {
  color: white;
}

#contactForm {
  border-radius: 1em;
  color: #381704;
}

#mainContent {
  height: 100%;
}

#loginButton {
  float: right;
}

#profileButton,
#logOutButton {
  float: right;
}

#projects {
  min-height: 320px;
  position: relative;
  text-align: center;
  margin: 30px auto;
}

#projects .carousel {
  border-top: 12px solid white;
  border-bottom: 12px solid white;
}

#projects h1 {
  margin-top: 0px;
  margin-left: 25px;
  font-weight: bold;
}

#projects .container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

#projectDetailsDiv {
  background-color: #381704;
  border-top-right-radius: 40%;
  border-bottom-right-radius: 70%;
  margin-top: 10%;
  margin-right: 25%;
  padding: 5%;
}

#services-list li {
  margin: 10px auto;
}

#social-row {
  display: flex;
  justify-content: center;
}

#social-row div {
  font-size: 200%;
  margin-right: 15px;
}

.add-project-btn {
  background-color: #333333 !important;
  border: 2px solid black !important;
}
.add-project-btn:hover {
  cursor: not-allowed;
  pointer-events: all !important;
}

.add-project-form {
  border-radius: 0.8em;
  color: #381704;
  padding: 2em 1em;
  width: 96%;
  max-width: 55rem;
}

.btn-primary {
  background-color: #305f0c;
}

.Building {
  background-color: orange;
  content: "In Progress";
  float: right;
}

.card {
  margin: 30px 20px;
}

.card-body {
  background-color: #33320e;
  color: white;
}

.card-footer {
  background-color: transparent;
  border: none;
  padding: 0;
}

.card-img {
  height: 200px;
}

.card-title {
  font-weight: 900;
  margin: 0;
  padding: 5%;
}

.carousel {
  height: 100%;
}

.carousel-inner {
  height: 100%;
  width: 100%;
}

.carousel-inner > .item {
  height: 100%;
  width: 100%;
}

.carousel-inner > .item > img {
  height: 100%;
  width: 100%;
}

.Complete {
  background-color: green;
}

.contact-header {
  margin-bottom: 25px;
}

.contact-type {
  padding: 2%;
}

.contact-type-header {
  font-weight: bolder;
}

.create-button {
  color: green;
}

.dark-background-header {
  color: white !important;
}

.Denied {
  background-color: red;
}

.form-control {
  background-color: #f7f3ee;
  color: #42230d;
}

.form-group {
  margin: 0 auto;
  margin-bottom: 1em;
}

.footer-link {
  color: white;
}

.footer-link:hover {
  color: gold;
}

.home {
  top: 0;
}

.home-page-title {
  color: #fff;
  font-weight: 900;
}

.item .active img {
  height: 100%;
  width: 100%;
}

.login-register-form {
  border: none;
  height: 100%;
  margin: 0;
  width: 100%;
}

.login-register-link {
  text-decoration: underline;
}

.login-register-page {
  background: -webkit-linear-gradient(rgb(53, 29, 3, 0.8), rgb(53, 29, 3, 0.8)),
    url("../img/woodgrain.jpg");
}

.main-title {
  font-size: 2em;
  font-weight: 900;
}

.nav-contact {
  color: #3f201a;
  margin: 0px 2px;
  font-size: 80%;
}

.navbar {
  box-shadow: 2px 2px 6px grey;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
}

.navbar-brand {
  font-weight: bolder;
}

.navbar-light .navbar-brand {
  color: #381704;
}

.navbar-light .navbar-toggler {
  border: none;
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb(90 54 2)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.no-projects {
  padding-bottom: 40px;
}

.Pending {
  background-color: gold;
}

.profile-page {
  padding: 30px 0px;
}
.project {
  background: #fff;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  padding: 0.5em;
  text-align: left;
}

.project-delete-button {
  align-items: center;
  display: flex;
  justify-content: center;
}

.project-details {
  height: 100%;
  margin: 1em auto 0;
  max-width: 35rem;
  width: 90%;
}

.project-details h2 {
  margin: 10px 30px;
  text-align: left;
  color: #295725;
}

.project-div {
  background-color: #804136;
  border-radius: 4px;
  margin-bottom: 20px;
}

.project-info {
  background: white;
  border: 2px solid silver;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  font-family: Montserrat-Black;
  font-size: 1em;
  justify-content: space-between;
  margin: 1em auto 0;
  max-width: 25rem;
  padding: 0.5em 0;
  text-align: center;
  width: 96%;
}

.project-info a {
  font-size: 150%;
}

.project-info a:hover {
  color: black;
}

.project-key {
  font-size: 1.25em;
  font-weight: 900;
  margin-right: 0.25em;
}

.project-list-title {
  font-size: 1.5em;
}

.project-name {
  color: #333333;
  font-size: 200%;
}

.project-name-and-delete {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 0 0.5em;
}

.projects-page {
  padding: 1em 0;
}

.project-row {
  display: flex;
  justify-content: space-evenly;
  margin: 0 !important;
}

.project-status-select {
  background-color: white;
  margin: 0px auto;
  width: 70%;
}

.project-title {
  background-color: #381704;
  border-bottom: 2px solid black;
  border-top-left-radius: 0.5em;
  border-top-right-radius: 0.5em;
  font-size: 300%;
  color: #ffffff;
  margin: 0;
  padding: 2%;
  text-align: center;
}

.status {
  border-left: 2px solid #66342b;
  color: white;
  float: right !important;
}

.title-with-button {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: 55rem;
  width: 96%;
}

#back-button {
  color: #fff;
  font-weight: 900;
  margin: 0 auto;
  max-width: 45rem;
  text-align: left;
  width: 96%;
}

#back-button svg {
  font-size: 2.5em;
  transform: rotate(180deg);
}

@media (min-width: 600px) {
  span {
    font-size: 100%;
  }

  .add-project-form-content {
    display: flex;
  }

  .add-project-form-content > div {
    width: 50%;
  }
  .card {
    margin: 30px auto;
  }

  .input-border {
    border: 1px solid #ced4da;
  }

  .project,
  .project-title {
    box-shadow: 2px 2px 14px 0px #0a0a0a;
  }

  #about {
    padding: 30px;
    text-align: center;
  }
  #background {
    min-height: 400px;
  }
}

.update-project-form {
  margin: 5%;
  text-align: center;
}

/*
  LARGE SCREENS
  */
@media screen and (min-width: 775px) {
  form {
    margin: 10px auto;
    text-align: center;
  }

  nav {
    position: relative;
    padding-left: 25px;
    padding: 25px;
  }

  p {
    font-size: 22px;
  }

  #background {
    min-height: 600px;
  }

  #background h1 {
    color: white;
    position: relative;
    top: 3%;
    margin: 25px;
    font-size: 26px;
    text-shadow: 3px 2px 12px black;
  }

  #projects {
    position: relative;
    width: 100%;
  }

  #projects .carousel {
    border-top: 12px solid white;
    border-bottom: 12px solid white;
  }

  #projects .container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  h1 {
    font-size: 32px;
    font-weight: bold;
  }

  .item img {
    height: 480px;
  }

  .login-register-form {
    width: 50%;
  }
}
